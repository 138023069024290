<template>
	<header class="intro" aria-labelledby="h1" aria-describedby="intro">
		<div class="hero">
			<h1
				class="headline"
				id="h1"
				v-html="$t(`pages[${page_index}].headline`)"
			/>
			<p
				class="lead"
				id="intro"
				v-html="$t(`pages[${page_index}].paragraph`)"
			/>
		</div>
		<svg class="waves" id="floaty-mcwaves-header" viewBox="0 0 100 15">
			<path fill="#ffffff" opacity="0.5" d="M0 30 V15 Q30 3 60 15 V30z"></path>
			<path fill="#fdfdff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
		</svg>
	</header>
</template>

<script>
export default {
	computed: {
		page_index() {
			return this.$route.meta.index
		}
	}
}
</script>
