<template>
	<article id="article" class="article">
		<Header />
		<transition
			name="list"
			tag="section"
			v-for="({ title, text }, key) in $t('pages[2].topics')"
			:key="key"
		>
			<section
				:aria-labelledby="`section-${key}`"
				:id="key"
				class="section"
				tabindex="0"
			>
				<h2 :id="`section-${key}`" v-html="title" v-if="key === 0" />
				<h3 :id="`section-${key}`" v-html="title" v-else />
				<p
					v-for="paragraph in $t(text)"
					:key="paragraph.key"
					v-html="paragraph"
				/>
			</section>
		</transition>
	</article>
</template>

<script>
import pages from '@/mixins/pages'

export default {
	mixins: [pages]
}
</script>
